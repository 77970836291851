@media (max-width: 1660px) {
  .das-menu .link-data {
    width: auto !important;
    display: block;
  }
  .right-content .head-name h3 {
    font-size: 22px;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  thead.thead-dark th {
    padding: 10px;
    font-size: 18px !important;
  }

  .das-menu .link-text {
    font-size: 20px;
    font-weight: 600;
    font-family: "Roboto", "sans-serif";
  }
}
@media (max-width: 1480px) {
  thead.thead-dark th {
    padding: 10px;
    font-size: 16px !important;
  }
  .blog-table td {
    font-size: 15px;
  }
  .das-menu .link-data {
    width: 310px !important;
  }
  .das-menu .menu-item {
    width: auto;
    padding: 5px;
    /* margin: 20px 14px !important; */
  }
  .right-content {
    margin-left: 0;
  }
  .side-menu {
    width: 100%;
  }
  p.link-text {
    font-size: 16px;
  }
  .menus-item .link-icon {
    font-size: 24px;
  }
  .notice-board.data-content h3 {
    font-size: 16px;
  }
}
@media (max-width: 1024px) {
  .loginbox {
    width: 370px;
    padding: 50px 30px 30px;
}
.input-field {
  margin: 10px 0 20px;
}
.avtar-image {
  width: 65px;
  height: 65px;
  top: -27px;
  line-height: 34px;
}

}

@media (max-width: 1199px) {
  table.table.table-bordered.blog-table {
    width: 100% !important;
    display: block;
    overflow-x: scroll;
  }
}

@media (max-width: 991px) {
  .pagination {
    display: block;
  }
  .modal-content {
    width: 44rem !important;
  }
  /* .das-menu .menu-item {
    width: 100% !important;
  } */
}
@media (max-width: 767px) {
  .menu-bar {
    display: block;
  }
  table.table.table-striped.table-hover.blog-table {
    width: 100%;
    display: block;
    overflow-x: scroll;
  }
}
